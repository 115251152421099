/* ********************************************************************************************
    Design for a width of 992px and above
*********************************************************************************************** */

@media only screen and (max-width: 1920px) and (min-width: 1400px) {

}  

/* ********************************************************************************************
    Design for a width of 768px and above
*********************************************************************************************** */

@media only screen and (min-width: 768px) {

}

/* ********************************************************************************************
    Design for a width of 992px and above
*********************************************************************************************** */

@media only screen and (min-width: 992px) {
    

}

/* ********************************************************************************************
    Design for a width of 1200px and above
*********************************************************************************************** */

@media only screen and (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1054px;
  }
}

/* ********************************************************************************************
    Design for a width of 1200px and below
*********************************************************************************************** */

@media only screen and (max-width: 1199px) {
    .home-section .home-person-image img {
        height: 580px;
    }
  }

/* ********************************************************************************************
    Design for a width of 992px and Above
*********************************************************************************************** */

@media only screen and (min-width: 992px) and (max-width: 1199px)  {
    
   
}

/* ********************************************************************************************
    Design for a width of 992px and below
*********************************************************************************************** */

@media only screen and (min-width: 768px) and (max-width: 991px)  {
    
}

/* ********************************************************************************************
    Design for a width of 1024px and below
*********************************************************************************************** */

@media only screen and (max-width: 1024px) {
    .h1, h1 {
        font-size: 2rem;
    }
    .font-size-45 {
        font-size: 35px;
    }
    .section .section-footer h3 {
        font-size: 1.8rem;
    }
    .content, .section {
        min-height: calc(100vh - 1rem);
    }
}

/* ********************************************************************************************
    Design for a width of 991px and below
*********************************************************************************************** */

@media only screen and (max-width: 991px) {
    .home-section {
        .home-person-image {
            display: none;
        }
    }
    .other-ways-to-get-hepb .col.mb-4:last-child {
        margin-bottom: 0rem !important;
    }
}

/* ********************************************************************************************
    Design for a width of 767px and below
*********************************************************************************************** */

@media only screen and (max-width: 767px) {
    body {
        font-size: 15px;
    }
    .h1, h1 {
        font-size: 1.5rem;
    }
    .font-size-45 {
        font-size: 1.5rem;
    }
    .h3, h3 {
        font-size: 1.2rem;
    }
    .btn-group-lg > .btn, .btn-lg {
        font-size: 1.125rem;
        padding: 0.7rem 1rem;
    }
    .btn-success-theme {
        min-height: 45px;
    }
    .mt-n5 {
        margin-top: 0rem !important;
    }
    .font-size-16 {
        font-size: 15px;
    }
    .section {
        .section-header, .section-body {
            padding: 1.5rem;
        }
        .section-header {
            text-align: left;
            padding-right: 5rem;
            padding-bottom: 0rem !important;
        }
        .section-footer {
            padding: 0px 1rem;
            height: 50px;
            h3 {
                font-size: 1.5rem;
            }
            img {
                height: 25px;
            }
        }
    }
    .play-icon {
        top: 24px;
        right: 25px;
        width: 32px;
    }
    .back-btn {
        min-width: 100px;
        font-size: 1rem;
        padding: 0.6rem 1rem;
    }
    .chapter-section .btn-success-theme {
        min-height: 60px;
    }
    .womens-business {
        .section {
            text-align: left !important;
            .section-image img {
                height: 200px !important;
                width: 200px !important;
            }
        }
    }
    .acknowledgements .section {
        .section-header {
            text-align: center;
            padding-right: 1.5rem !important;
        }
        .col:first-child br {
            display: none;
        }
        .section-body img {
            height: 60px;
        }
    }
    .game-level-container, .game-result-container  {
        .btn {
                        height: 70px;
                        font-size: 20px;
                        font-weight: 500;
                        margin-bottom: 16px !important;
                    }
                }
                .game-result-container {
                    .h3, h3 {
                        font-size: 1.50rem !important;
                        line-height: 32px;
                    }
                }
                .liver-image {
                    max-width: 30%;
                    }
                .day-4-img, .day-8-img {
                    top: -2rem;
                }
                .day-box {
                    height: 70px;
                    width: 62px;
                    .day-text {
                        margin: 7px 0px 2px !important;
                        font-size: 16px;
                    }
                }
                .timer-box {
                    right: 25px;
                    height: 55px;
                    width: 57px;
                    font-size: 30px;
                }
                .menzies-logo img {
                    height: 65px;
                }
                .home-section .btn-success-theme {
                    font-size: 1.2rem;
                    min-height: 50px;
                    img {
                        width: 35px;
                    }
                    br {
                        display: none;
                    }
                }
                .page-not-found-container {
                    img {
                        width: 100px;
                    }
                }
                .vid-img-size {
                    height: 200px;
                    width: 100%;
                }
                .sound-icon, .play-icon {
                    top: 24px;
                }
                .sound-icon img {
                    height: 28px;
                }
}

/* ********************************************************************************************
    Design for a width of 639px and below
*********************************************************************************************** */

@media only screen and (max-width: 639px) {
    
}

/* ********************************************************************************************
    Design for a width of 500px and below
*********************************************************************************************** */

@media only screen and (min-width: 576px) {
    .modal-dialog {
        max-width: 420px;
    }
    .warning-modal .modal-dialog {
       max-width: 490px;
    }
}

/* ********************************************************************************************
    Design for a width of 520px and below
*********************************************************************************************** */

@media only screen and (min-width: 480px) and (max-width: 520px)  {
   
}

/* ********************************************************************************************
    Design for a width of 479px and below
*********************************************************************************************** */

@media only screen and (max-width: 479px) {

}

/* ********************************************************************************************
    Design for a width of 380px and below
*********************************************************************************************** */

@media only screen and (max-width: 380px) {

}

/* ********************************************************************************************
    Design for a width of 320px and below
*********************************************************************************************** */

@media only screen and (max-width: 320px) {    

}
