/* ********************************************************************************************
    Reset
*********************************************************************************************** */

body {
  font: 17px/1.5 "Roboto", sans-serif;
  font-weight: 400;
  color: #000;
  background: #f2f2f2;
}
a {
  color: #be2221;
  text-decoration: none;
}
a:hover {
  color: #c23b33;
}
.text-primary-theme {
  color: #c23b33;
}
h1 {
  font-size: 2.5rem;
  font-weight: 400;
  color: #231f20;
}
.h6 {
  font-size: 1.063rem;
  font-weight: 900;
  color: #231f20;
}
img {
  max-width: 100%;
}
.rounded {
  border-radius: 6px !important;
}
.cursor-default {
  cursor: default !important;
}
a.disabled {
  opacity: 0.5;
  cursor: default;
}
.p-b-40 {
  padding-bottom: 40px;
}
.font-size-16 {
  font-size: 16px;
}
.font-size-18 {
  font-size: 18px;
}
.font-size-45 {
  font-size: 45px;
}
.font-weight-900 {
  font-weight: 900;
}

/* ********************************************************************************************
    Button 
*********************************************************************************************** */
.btn {
  width: 100%;
  font-weight: 500;
  border-radius: 8px;
}
.btn:focus {
  box-shadow: none;
}
.btn-primary-theme {
  background-color: #c23c33;
  color: #fff;
  &:hover {
    color: #fff;
  }
}
.btn-secondary-theme {
  background-color: #e2dfd8;
  color: #231f20;
}
.btn-success-theme {
  background-color: #22515d;
  color: #fff;
  &:hover {
    color: #fff;
  }
}
.btn-success-theme {
  line-height: 24px;
  min-height: 65px;
}

.btn-outline {
    background-color: transparent !important;
    border: 3px solid #22515d !important;
    color: #22515d !important;
    &:hover {
      background-color: #22515d !important;
      color: #fff !important;
    }
  }

.chapter-section .btn-success-theme,
.home-section .btn-success-theme {
  min-height: 82px;
  position: relative;
  &::before {
    position: absolute;
    top: 0px;
    left: -3px;
    content: "";
    background-image: url('assets/images/btn-bg-image.svg');
    background-repeat: no-repeat;
    background-position: left;
    width: 100%;
    height: 100%;
  }
}
.home-section .btn-success-theme {
    text-align: left;
    font-size: 1.4rem;
    br {
      display: none;
    }
  }
.btn-group-lg>.btn, .btn-lg {
  padding: 1rem;
}

.btn-sound {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
}

/* ********************************************************************************************
    Pages 
*********************************************************************************************** */
.content {
  position: relative;
  background-image: url('assets/images/bg-image.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  margin-top: 1rem;
  min-height: 768px;
  &.home {
    background-image: url('assets/images/bg-image-home.png');
    background-color: #fff;
  }
  &.story {
    background-image: url('assets/images/bg-image-story.jpg');
  }
}
.section {
  text-align: center;
  min-height: 768px;
  .section-header {
    padding: 1.5rem 5.5rem 1.5rem;
  }
  .section-body {
    padding: 1.5rem 2rem;
  }
  .section-body p:last-child {
    margin-bottom: 0px;
  }
  .section-footer {
    position: relative;
    background-image: url('assets/images/footer-img.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 63px;
    padding: 0rem 1.5rem;
    display: flex!important;
    justify-content: center!important;
    h3 {
      font-size: 2.188rem;
      font-weight: 900;
      text-shadow: 1px 0 0 rgba(255, 255, 255, 0.25), -1px 0 0 rgba(255, 255, 255, 0.25), 0 1px 0 rgba(255, 255, 255, 0.25), 0 -1px 0 rgba(255, 255, 255, 0.25), 1px 1px rgba(255, 255, 255, 0.25), -1px -1px 0 rgba(255, 255, 255, 0.25), 1px -1px 0 rgba(255, 255, 255, 0.25), -1px 1px 0 rgba(255, 255, 255, 0.25);
    }
  }
}
.chapter-section .section-header {
  padding-bottom: 0.3rem;
}

.btn-container {
  &.main {
    max-width: 440px;
  }
}
.close-icon {
  position: absolute;
  top: 0px;
  right: 0px;
  display: block;
  padding: 15px 15px 30px 30px;
  border-radius: 0px 0px 0px 100px;
  background-color: #c23c33;
}
.sound-icon, .play-icon {
  position: absolute;
  top: 27px;
  right: 20px;
  display: block;
}

.play-icon {
  top: 29px;
  right: 25px;
}

.back-btn {
  font-size: 17px;
  width: auto;
  min-width: 115px;
  padding: 0.7rem 1rem;
  text-transform: uppercase;
}
.home-person {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

  .language .btn-success-theme {
      text-align: left;
      .language-arrow-check {
        display: none;
      }
      &:hover {
        border: 3px solid #74ba1c !important;
        .language-arrow-check {
          display: inline-block;
        }
        .language-arrow-default {
          display: none;
        }
      }
    }
    
    .home-section {
      .home-person-image {
        position: absolute;
        bottom: 0px;
        right: 0px;
        img {
          height: 660px;
        }
      }
    }
  
  .globe-tag {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 16px;
    font-weight: 500;
    color: #b0b0b0;
    svg {
      margin-top: -2px;
    }
    &.online {
      color: #5aa01b;
      svg {
        fill: #5aa01b;
      }
    }
  }

.game-level-container, .game-result-container {
    max-width: 305px;
    margin: 0 auto;
    .btn {
      height: 95px;
      font-size: 27px;
      font-weight: 700;
      border-radius: 12px;
      text-transform: uppercase;
      background-color: #d9531e;
    }
  }
  .game-result-container {
    max-width: 420px;
    h3 {
      line-height: 38px;
    }
    .btn {
      font-size: 26px;
      background-color: #c43a31;
      min-width: 256px;
    }
    &.failure {
      max-width: 520px;
    }
  }
  .day-box {
    position: relative;
    background-image: url('assets/images/day.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 82px;
    width: 74px;
    margin: 0px 10px 10px 0px;
    .day-text {
      margin: 9px 0px 3px !important;
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: bold;
    } 
    .day-tick {
      width: 50%;
    }
  }

  .timer-box {
    position: absolute;
        top: 80px;
        right: 50px;
        height: 65px;
        width: 69px;
        font-size: 36px;
        background-image: url('assets/images/timer-box.png');
        background-repeat: no-repeat;
        background-size: cover;
        color: red;
        display: flex!important;
        align-items: center!important;
        justify-content: center!important;
    }
    .day-4-img, .day-8-img {
      position: relative;
      top: -3rem;
    }
    .day-8-img {
      top: -3.5rem;
    }
    .day-7-img {
      position: relative;
      bottom: -1.5rem;
    }
    .react-draggable {
      display: inline-block;
    }
    .page-not-found-container {
      max-width: 650px;
      margin: 0 auto;
      .btn {
        min-width: 180px;
      }
    }
    .game-container {
      .game-image {
        position: relative;
      }
      .game-image-placeholder {
        position: absolute;
        top: -2%;
        right: 21.5%;
        height: 60px !important;
        width: 60px !important;
        z-index: 9 !important;
        img {
          height: 60px !important;
          width: 60px !important;
          margin: 0px !important;
          opacity: 0;
        }
        &.day-two {
          top: 6%;
          left: 17%;
          right: auto;
        }
        &.day-three {
          right: 23%;
        }
        &.day-four {
          right: auto;
          left: 10%;
          top: -18%;
        }
        &.day-five {
          top: -1%;
          right: 34%;
        }
        &.day-six {
          top: 6%;
          left: 24%;
          right: auto;
        }
        &.day-seven {
          top: 15%;
          right: 19%;
        }
        &.day-eight {
          top: -19%;
          right: auto;
          left: 19%;
        }
      }
    }
    .notransition {
      display: none !important;
      opacity: 0 !important;
      visibility: hidden !important;
      // -webkit-transition: none !important;
      // -moz-transition: none !important;
      // -ms-transition: none !important;
      // -o-transition: none !important;
      // transition: none !important;
    }
    
    .vid-img-size {
      max-height: 370px;
      width: auto;
    }
    .loader-container {
      position: fixed;
      z-index: 2000;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.6);
    }
    .loader {
        width: 55px;
        height: 55px;
        border: 4px solid #ccc;
        border-top: 4px solid #c23c33;
        border-radius: 50%;
        animation: spin 2s linear infinite;
        margin: 0px auto;
    }
    @keyframes spin {
      from {
          transform:rotate(0deg);
      }
      to {
          transform:rotate(360deg);
      }
  }

/* ********************************************************************************************
    Modal
*********************************************************************************************** */
.modal {
  position: absolute;
  &::before {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #000;
    opacity: 0.5;
    content: "";
    height: 100%;
    width: 100%;
  }
}

.modal-header {
    border-bottom: 1px solid #e5e5e5;
    .close {
      position: absolute;
      top: 3px;
      right: 8px;
      padding: 0.7rem;
      margin: 0;
      color: #fff;
      opacity: 0.8;
    }
    .close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
      opacity: 0.8;
    }
  }

.modal-body, .modal-footer {
  background-color: #fff;
  z-index: 9999;
}
.modal-footer {
  .btn {
    width: 50%;
    border: none;
    margin: 0px;
    border-radius: 0px;
    font-weight: 700;
    font-size: 20px;
    color: #fff;
  }
  .btn-secondary-theme {
    background-color: #b7b09d;
  }
}
.modal-backdrop.show {
  opacity: .7;
}

.warning-modal {
    &::before {
      background-color: #ffffff;
      opacity: 0.6;
    }
    .modal-body {
      background: #222;
    }
    .modal-content {
      background: #222;
      color: #ffffff;
      box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    }
  }

/* ********************************************************************************************
    Animation
*********************************************************************************************** */
a,
.btn,
.collapse {
    -webkit-transition: color 0.3s ease-in-out, background 0.3s ease-in-out, opacity 0.3s ease-in-out, border 0.3s ease-in-out, font-weight 0.3s ease-in-out;
    transition: color 0.3s ease-in-out, background 0.3s ease-in-out, opacity 0.3s ease-in-out, border 0.3s ease-in-out, font-weight 0.3s ease-in-out;
}

.webview {
  background-image: none !important;
}

.link {
  text-decoration: underline;
  font-style: italic;
}